<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席源创会，探讨数据技术的未来发展
              </p>
              <div class="fst-italic mb-2">2023年10月10日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >源创会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/59/01-赵海峰.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    9月23日，由开源中国联合腾讯云TVP开展的“数据与前沿技术”源创会活动在成都顺利举行，非凸科技受邀出席，与业界专家们共同探讨了数据存储、数据分析、数据挖掘等前沿技术。
                  </p>
                  <p>
                    会上，非凸科技成都分公司研发总监赵海峰以“数智交易的数据驱动”为主题进行了分享。在数智交易领域如何高效地获取行情数据，如何将行情数据转发到需要的服务器，如何处理大量历史行情数据的存放和读取，又是如何通过行情数据进行模型的训练，赵海峰老师一一做出了精彩的解答。活动后，引发线上热烈交流讨论。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/59/02-行情数据.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    数智交易主要通过行情数据进行交易决策，那么如何通过券商获取行情，进行行情低延迟接收的软硬件方案呢？交易所发布的行情会经过券商的处理再转发给交易机构，其转发途径主要有TCP、UDP、FPGA加速的UDP和ASIC加速的UDP行情等。然而，券商通过TCP连接将处理后的行情数据转发给交易机构，会存在延迟大、应用层丢包（非TCP协议丢包）、发送端负载大等问题。为了解决这些问题，券商又通过UDP组播或广播的方式，将处理后的行情或交易所原始行情转发给交易机构。为了达到极致的低延迟，券商端将会通过多种方式来解决，其中一个特别有效的方式是使用L1交换机，在一层转发光或电信号给客户，其转发延迟可以低至4ns。需要注意的是，虽然UDP不是一个可靠传输协议，但在同一个交换机连接的服务器之间使用UDP进行通信，正常情况下在网络上几乎不会丢包。然而，在客户端程序和服务器的网卡上可能会丢包。因此，客户在接收行情时，可以使用无锁的ring buffer转发数据到处理线程，以并行处理不同股票的行情，然后将处理结果写入共享内存，以供交易系统读取。
                  </p>
                  <p>
                    收到行情后，如何将行情数据转发给内部的其他消费者呢？如果对延迟要求没有太高，可以使用TCP转发行情，能够自己控制丢包率，为了降低延迟和增加吞吐，也可以使用UDP转发行情。由于逐笔行情不允许丢包，所以在使用UDP转发行情时，可以搭配TCP行情重传服务，通过多路行情汇聚、Rocksdb持久化等方式对UDP转发行情进行补充。如果转发行情前进行数据压缩，那么延迟和吞吐量可能会更优秀。行情压缩主要有两种方式：行情消息的压缩、消息内部字段的压缩（股票代码、价格）。
                  </p>
                  <p>
                    行情转发之后，如何使用行情数据分析交易执行情况，又该如何训练模型呢？收取到行情后，其中一种应用场景是训练数智交易模型，将收取到的行情数据进行特征处理，提取因子，并利用AI进行模型训练，然后将训练好的模型解析出来以备高效地计算实时信号，在接收到实时信号值之后，再极速推送到交易系统，就可以根据不同的策略配置触发交易；另一种场景应用是把收取到的行情数据与ClickHouse集成，这不仅能提供高效的聚合和分析查询功能，还能使用流式聚合表自动计算交易数据，如实时交易盈亏，风险指标等。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/59/03-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技正在加大对金融科技研究的投入，持续以行业技术交流与合作的方式，整合行业生态优势资源，加快创新技术在实际业务场景中的落地。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News59",
};
</script>

<style></style>
